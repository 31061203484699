import React, { ReactNode, useEffect } from 'react'
import { StaticImage } from "gatsby-plugin-image"

import { Header } from '@components/header'
// import {Menu, MenuRow} from './style'
import tw, { styled } from 'twin.macro'
import { StyledLink } from './style'
import { colorGold, darkGrey } from '@styles/color'
import { MenuComponent } from './menu'
import { Link } from 'gatsby'

const ContactLine = styled.a`
  ${tw`flex mt-2 mb-1`}
  color: white;
  align-items: center;
  svg {
    display: inline-block;
    margin-right: 7px;
  }
`

export const Logo = styled(StaticImage)`
  max-height: 82px;
  max-width: 216px;

  position: absolute;
  top: 12px;
`

export const MenuContainer = styled('div')`
  ${tw`flex items-center md:justify-between mb-6 lg:mb-0 sm:flex-row flex-col`}
`

export const StyledLinkL = styled(StyledLink)`
  ${tw`text-base`}
  letter-spacing: 1px;
`;

const PageContainer = styled('div')`
  ${tw`sm:m-1 mx-0`}

  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
`

const FooterContainer = styled('div')`
  width: 100%;
  background-color: ${darkGrey};
  box-sizing: border-box;
  ${tw`flex md:flex-row flex-col justify-evenly text-white p-6  px-16 mt-12`}
  line-height: 1.5;
  div {
    ${tw`mb-1`}
  }
`

const CopyRight = styled('div')`
  color: rgb(211 211 211 / 0.67);
  font-size: 11px;
  ${tw`flex flex-row justify-between text-white  mt-4 md:block hidden`}
`

const MediaLink = styled('a')`
  ${tw`block text-white hover:text-gray-500 mt-2`}
  display: flex;
  align-items: center;
`

export const StyledWhiteLink = styled(Link)`
  ${tw`text-white text-xs font-semibold antialiased tracking-wide m-3 p-3 transition-colors duration-300 ease-in-out cursor-pointer`}
  &:hover {
    color: ${colorGold};
  }
  letter-spacing: 1.1px;
  font-weight: 700;

  text-transform: uppercase;
`;

export const  Layout = ({prop, data, children }) => {
  useEffect(() => {

  });
  return (
    <PageContainer>
      <Header prop={prop}/>
      <MenuComponent></MenuComponent>

      {children}

      <FooterContainer>
        <div>
            <ContactLine href="tel:61 306 71 01">
              <svg xmlns="http://www.w3.org/2000/svg"
                width="20" height="20" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#ffffff" fill="none"
                strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
              <span>
                61 306 71 01
              </span>
            </ContactLine>
            <ContactLine href="tel:61 306 71 02">
              <svg xmlns="http://www.w3.org/2000/svg"
                width="20" height="20" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#ffffff" fill="none"
                strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
              <span>
                61 306 71 02
              </span>
            </ContactLine>
            <ContactLine href="mailto:mediroot.wagrowiec@gmail.com">
              <svg xmlns="http://www.w3.org/2000/svg"
                width="22" height="22" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#ffffff" fill="none"
                strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>
              <span>
                mediroot.wagrowiec@gmail.com
              </span>
            </ContactLine>
          <MediaLink
            href="https://www.facebook.com/mediroot"
            title="Facebook"
            rel="nofollow"
            target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="22" height="22"
              viewBox="0 0 24 24" strokeWidth="1.5"
              stroke="white" fill="none"
              strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
            </svg>
            <span>
              /mediroot
            </span>
          </MediaLink>
          <CopyRight>
            Copyright Mediroot © { new Date().getFullYear()}
          </CopyRight>
        </div>

        <div>
          ul. św. Wojciecha 16<br/>
          Wągrowiec 62-100<br/>
          NIP: 606-000-98-35 <br/>
          REGON: 221921252
        </div>

        <div>
          <StyledWhiteLink to="/rodo">RODO</StyledWhiteLink><br/>
          <StyledWhiteLink to="/regulamin-mediroot">Regulamin</StyledWhiteLink>
        </div>

        <div>
          {/* spacer */}
        </div>
      </FooterContainer>
    </PageContainer>
  )
}

